
.catalog-container{
    background-color: #000;
}
.items img{
    width: 80%;
    object-fit: cover;
    height : auto;
    max-height: auto;
    padding: 20px;
}

.centered{
    text-align: center;
    color: white;
}
.home h1{
    transition: ease all .2s;
}
.home h1:active{
    transform: scale(1.1);
}

.centered h1{
    margin-block: 0px;
}
.items{
    width: 80%;
    margin-left: calc(20% - 40px);
    display: flexbox;
}