body {
  margin: 0;
  background-color: transparent;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: none;
}



.unblur{
  filter: none !important;
}

.app-content{
  background-color: transparent;
}
.banner{
  width: 400px;
  height: auto;
  border-left: 60px solid black;

  border-radius: 4px;
  border-right: 60px solid black;

}
.side-logo{
  font-family: "Roboto", sans-serif;
  text-decoration: none;
  color: white;
  font-weight: 500;
  position: fixed;
  transform: rotate(-90deg);
  top: 32px;
  right: -36px;
}
.sub-buttons{
  text-align: left;
  position: fixed;
  top: 8px;
  left: 12px;
  
}
.sub-buttons a{
  text-align: left;
  min-width: 70px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  color: white;
  text-decoration: none;
  font-size: 14px;
}
.sub-buttons img{
  height: 150px;
  background-color: #000;
  padding: 1em;
  display: inline-block;
}

.cart-icon{
  position: fixed;
  top: 6px;
  right: 4px;
  width: 24px;
  height: 24px;
}


.cart-icon path{
  fill: white;
}
.app-content .content {
  margin-top: 200px;
  text-align: center;
  color: white;
  font-family: "Roboto", sans-serif;
}



.header{
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  padding: 0px;
  margin-block: 0px;
  font-size: 14px;
  position: absolute;
  top: 14px;
  left: 20px;
  z-index: 100;;
  background-color: transparent;
  color: white;
}

.overlay {
  transition: ease all .1s;
  filter: blur(4px);
  position: fixed;
  z-index: -1;
    right: 0;
    bottom: 0;
    min-width: 100%; 
    min-height: 100%;
}

.socials{
  position: fixed;
  bottom: 4px;
  right: 0px;
}
.socials a{
  width: 34px;
  padding-right: 3px;
  padding-left: 3px;
}
.socials img, svg{
  display: inline-block;
  width: 24px;
  height: 24px;
  padding-right: 6px;
  cursor: pointer;
}



/* Lock*/

.lock-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
}
/* :::::::::::::: Required CSS */
/* Locked */
.lock {

  transform: scale(.8);
  width: 24px;
  height: 21px;
  border: 3px solid #fff;
  border-radius: 5px;
  position: absolute;
  top: calc(50% - 10px);
  z-index: 100;
  cursor: pointer;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.lock:after {
  content: "";
  display: block;
  background: #fff;
  width: 3px;
  height: 7px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -3.5px 0 0 -2px;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.lock:before {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  bottom: 100%;
  position: absolute;
  left: 50%;
  margin-left: -8px;
  border: 3px solid #fff;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom: 0;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
/* Locked Hover */
.lock:hover:before {
  height: 12px;
}
.lock:active{
  transform: scale(1.05);
}
/* Unlocked */
.unlocked {
  transform: rotate(10deg);
}
.unlocked:before {
  bottom: 130%;
  left: 31%;
  margin-left: -11.5px;
  transform: rotate(-45deg);
}
.unlocked,
.unlocked:before {
  border-color: #fff;
}
.unlocked:after {
  background: #fff;
}
/* Unlocked Hover */
.unlocked:hover {
  transform: rotate(3deg);
}
.unlocked:hover:before {
  height: 10px;
  left: 40%;
  bottom: 124%;
  transform: rotate(-30deg);
}
#spotify{
  transform: scale(.9);
}
#vsco{
  transform: scale(.9);
}
#twitter{
  transform: scale(1.1);
}
#youtube{
  transform: scale(.9) translate(2px,1px);
  padding-right: 0px;
  width: 40px;
}


#magicMouseCursor{
  transition: 0s;
}